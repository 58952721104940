import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "../images/logo-header-black.png"

const navigation = [
  { name: 'HOME', href: '/#top' },
  { name: 'ABOUT US', href: '/#about-us' },
  { name: 'TESTIMONIALS', href: '/#testimonials' },
  { name: 'FOREX ANALYTIX', href: 'https://www.forexanalytix.com/' },
]

const Menu2 = () => {
  return (
    <Popover className="relative bg-gray-900 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/#top">
              <span className="sr-only">Trader Coach</span>
              <img
                className="h-14 w-auto sm:h-14"
                src={logo}
                alt="Trader Coach Header"
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-green-400 p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">

            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="font-bold text-base font-medium text-white hover:text-green-400">
                {item.name}
              </a>
            ))}

          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">

          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-14 w-auto"
                    src={logo}
                    alt="Trader Coach Logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-base font-bold text-gray-900 hover:text-green-400">
                    {item.name}
                  </a>
                ))}
              </div>

            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default Menu2
